import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { ThemeProvider } from 'styled-components'
import App from 'containers/App'
import { AppProvider } from 'services/context/appContext/AppContext'
import connectTheme from 'styles/themes'
import { BaseConnectStyle } from 'styles/base'
import 'styles/normalize.css'
import 'styles/flexboxgrid.css'
import { name, version } from '../package.json'

console.log('You are using Correlation One ' + name + ' version: ' + version)

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW
  }

  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <AppProvider>
    <ThemeProvider theme={connectTheme}>
      <Router>
        <App />
      </Router>
      <BaseConnectStyle />
    </ThemeProvider>
  </AppProvider>,
  document.getElementById('root')
)
