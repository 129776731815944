export const requests = (param = null) => {
  return {
    // auth endpoints
    token: {
      endpoint: 'token',
      auth: false,
      method: 'post'
    },
    signup: {
      endpoint: 'accounts/sign-up',
      auth: false,
      method: 'post'
    },
    activate: {
      endpoint: `accounts/activate/${param}`,
      auth: false,
      method: 'patch'
    },
    logout: {
      endpoint: 'accounts/logout',
      auth: true,
      method: 'post'
    },
    refreshTokens: {
      endpoint: 'token/refresh',
      auth: false,
      method: 'post'
    },
    requestPasswordReset: {
      endpoint: 'accounts/reset',
      auth: false,
      method: 'post'
    },
    resetPassword: {
      endpoint: `accounts/change/${param}`,
      auth: false,
      method: 'patch'
    },
    // candidate profile endpoints
    publicProfile: {
      endpoint: `profiles/${param}`,
      auth: false,
      method: 'get'
    },
    profile: {
      endpoint: 'my-profile',
      auth: true,
      method: 'get'
    },
    patchProfile: {
      endpoint: `my-profile`,
      auth: true,
      method: 'patch'
    },
    // crud actions for profile
    createSkill: {
      endpoint: 'my-profile/skills',
      auth: true,
      method: 'post'
    },
    updateSkill: {
      endpoint: `my-profile/skills/${param}`,
      auth: true,
      method: 'put'
    },
    patchSkills: {
      endpoint: `my-profile`,
      auth: true,
      method: 'patch'
    },
    createCertificate: {
      endpoint: 'my-profile/certificates',
      auth: true,
      method: 'post'
    },
    updateCertificate: {
      endpoint: `my-profile/certificates/${param}`,
      auth: true,
      method: 'put'
    },
    deleteCertificate: {
      endpoint: `my-profile/certificates/${param}`,
      auth: true,
      method: 'delete'
    },
    createPublication: {
      endpoint: 'my-profile/publications',
      auth: true,
      method: 'post'
    },
    updatePublication: {
      endpoint: `my-profile/publications/${param}`,
      auth: true,
      method: 'put'
    },
    deletePublication: {
      endpoint: `my-profile/publications/${param}`,
      auth: true,
      method: 'delete'
    },
    createAward: {
      endpoint: 'my-profile/awards',
      auth: true,
      method: 'post'
    },
    updateAward: {
      endpoint: `my-profile/awards/${param}`,
      auth: true,
      method: 'put'
    },
    deleteAward: {
      endpoint: `my-profile/awards/${param}`,
      auth: true,
      method: 'delete'
    },
    createWorkExperience: {
      endpoint: 'my-profile/work-experiences',
      auth: true,
      method: 'post'
    },
    updateWorkExperience: {
      endpoint: `my-profile/work-experiences/${param}`,
      auth: true,
      method: 'put'
    },
    deleteWorkExperience: {
      endpoint: `my-profile/work-experiences/${param}`,
      auth: true,
      method: 'delete'
    },
    createEducation: {
      endpoint: 'my-profile/educations',
      auth: true,
      method: 'post'
    },
    updateEducation: {
      endpoint: `my-profile/educations/${param}`,
      auth: true,
      method: 'put'
    },
    deleteEducation: {
      endpoint: `my-profile/educations/${param}`,
      auth: true,
      method: 'delete'
    },
    createProject: {
      endpoint: 'my-profile/projects',
      auth: true,
      method: 'post'
    },
    updateProject: {
      endpoint: `my-profile/projects/${param}`,
      auth: true,
      method: 'put'
    },
    featureProject: {
      endpoint: `my-profile/projects/${param}`,
      auth: true,
      method: 'patch'
    },
    deleteProject: {
      endpoint: `my-profile/projects/${param}`,
      auth: true,
      method: 'delete'
    },
    uploadAsset: {
      endpoint: 'my-profile/assets',
      auth: true,
      method: 'post'
    },
    createBasic: {
      endpoint: 'my-profile',
      auth: true,
      method: 'put'
    },
    createGeneralPreferences: {
      endpoint: 'my-profile/general-preferences',
      auth: true,
      method: 'put'
    },
    createEmployerPreferences: {
      endpoint: 'my-profile/employer-preferences',
      auth: true,
      method: 'put'
    },
    getGeneralPreferences: {
      endpoint: 'my-profile/general-preferences',
      auth: true,
      method: 'get'
    },
    getEmployerPreferences: {
      endpoint: 'my-profile/employer-preferences',
      auth: true,
      method: 'get'
    },
    updateBasic: {
      endpoint: 'my-profile',
      auth: true,
      method: 'patch'
    },
    updateGeneralPreferences: {
      endpoint: 'my-profile/general-preferences',
      auth: true,
      method: 'put'
    },
    updateEmployerPreferences: {
      endpoint: 'my-profile/employer-preferences',
      auth: true,
      method: 'put'
    },
    updateProfileConfiguration: {
      endpoint: 'my-profile/configuration',
      auth: true,
      method: 'patch'
    },
    getInspirationPieces: {
      endpoint: 'inspiration-page',
      auth: true,
      method: 'get'
    },
    getPublicInspirationPieces: {
      endpoint: 'inspiration-page',
      auth: false,
      method: 'get'
    },
    getMyJobs: {
      endpoint: `employers/job-board/my-jobs`,
      auth: param?.authed,
      method: 'get'
    },
    saveJob: {
      endpoint: `employers/saved-job`,
      auth: param?.authed,
      method: 'post'
    },
    deleteJob: {
      endpoint: `employers/saved-job/${param?.jobListingId}`,
      auth: param?.authed,
      method: 'delete'
    },
    getJobListings: {
      endpoint: param?.next
        ? param?.next
        : `employers/job-board${param?.query || ''}`,
      auth: param?.authed,
      method: 'get'
    },
    getJobListing: {
      endpoint: `employers/job-board/${param?.jobListingId}`,
      auth: param?.authed,
      method: 'get'
    },
    getEmployerIndustryList: {
      endpoint: `employers/employer-industry-list`,
      auth: param?.authed,
      method: 'get'
    },
    getExperienceList: {
      endpoint: `employers/experience-level-type-list`,
      auth: param?.authed,
      method: 'get'
    },
    getProfessionList: {
      endpoint: `employers/profession-type-list`,
      auth: param?.authed,
      method: 'get'
    },
    getLocationList: {
      endpoint: `employers/location-list`,
      auth: param?.authed,
      method: 'get'
    },
    getSuggestableSkills: {
      endpoint: `skill-list`,
      auth: param?.authed,
      method: 'get'
    },
    shareProfile: {
      endpoint: `employers/job-board-share-profile`,
      auth: true,
      method: 'post'
    },
    completeExternalApplication: {
      endpoint: `employers/job-board-external-application`,
      auth: true,
      method: 'post'
    },
    undoExternalApplication: {
      endpoint: `employers/job-board-external-application/${param?.jobListingId}`,
      auth: true,
      method: 'delete'
    },
    getEmailActivationState: {
      endpoint: `accounts/check-account-exists/${param?.currentEmail}`,
      auth: true,
      method: 'get'
    },
    changeEmail: {
      endpoint: `accounts/change-email`,
      auth: true,
      method: 'post'
    },
    confirmEmailChange: {
      endpoint: `accounts/change-email/${param?.token}`,
      auth: true,
      method: 'patch'
    },
    getGeocodingPlace: {
      externalEndpoint: `https://api.geocode.earth/v1/autocomplete?api_key=${process.env.REACT_APP_GEOCODE_API_KEY}&layers=locality&text=${param}`,
      auth: false,
      method: 'get'
    }
  }
}
