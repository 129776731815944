import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useFetchAppData from 'services/hooks/useFetchAppData'
import HowToShowcasePage from 'containers/HowToShowcasePage'
import JobBoardPage from 'containers/JobBoardPage'
import JobListingPage from 'containers/JobListingPage/JobListingPage'
import Navbar from 'components/nav/Navbar'
import ProfilePage from 'containers/ProfilePage'
import ProjectPage from 'containers/ProjectPage/ProjectPage'
import QuestionnairePage from 'containers/QuestionnairePage'
import SettingsPage from 'containers/SettingsPage'
import { ProfileProvider } from 'services/context/profileContext/ProfileContext'
import { SettingsProvider } from 'services/context/settingsContext/SettingsContext'
import connectTheme from 'styles/themes'
import ConfirmEmailAddress from 'containers/App/components/ConfirmEmailAddress'
import NotFoundPage from 'containers/ErrorPage/NotFoundPage'

const AuthedApp = () => {
  const { state } = useFetchAppData()
  const {
    profileData,
    onboarding,
    generalPreferencesData,
    employerPreferencesData
  } = state

  if (onboarding || !profileData?.resume) {
    if (profileData && !profileData.resume) {
      return (
        <Switch>
          <Route path="/questionnaire" children={<QuestionnairePage />} />
          <Redirect to="/questionnaire/resume-upload" />
        </Switch>
      )
    }

    return (
      <Switch>
        <Route path="/questionnaire" children={<QuestionnairePage />} />
      </Switch>
    )
  }

  if (!profileData || !generalPreferencesData || !employerPreferencesData)
    return <div>Loading...</div>

  return (
    <>
      <Navbar indexing={connectTheme.order.navbar} />
      <Switch>
        <Route path="/showcase" component={HowToShowcasePage} />
        <Route exact path="/jobs" component={JobBoardPage} />
        <Route path="/jobs/:job" component={JobListingPage} />
        <Route exact path="/profile/:user/projects">
          <ProfileProvider>
            <ProfilePage isProjects />
          </ProfileProvider>
        </Route>
        <Route path="/profile/:user/projects/:project">
          <ProfileProvider>
            <ProjectPage />
          </ProfileProvider>
        </Route>
        <Route path="/profile/:user">
          <ProfileProvider>
            <ProfilePage />
          </ProfileProvider>
        </Route>
        <Route path="/settings">
          <SettingsProvider>
            <ProfileProvider>
              <SettingsPage />
            </ProfileProvider>
          </SettingsProvider>
        </Route>
        <Route exact path="/change-email">
          <ConfirmEmailAddress />
        </Route>
        <Route path="/not-found-page" component={NotFoundPage} />
        <Route>
          <Redirect to={`/profile/${profileData.slug}`} />
        </Route>
      </Switch>
    </>
  )
}

export default AuthedApp
