import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { Editor, EditorState, convertFromRaw } from 'draft-js'
import { markdownToDraft } from 'markdown-draft-js'
import JobLocationInfo from 'components/general/JobLocationInfo'
import groupIcon from 'containers/JobListingPage/assets/groupIcon'
import incomeIcon from 'containers/JobListingPage/assets/incomeIcon'
import suitcaseIcon from 'containers/JobListingPage/assets/suitcaseIcon'
import clockIcon from 'containers/JobBoardPage/assets/clockIcon'
import companyLogoPlaceholder from 'containers/JobBoardPage/assets/companyLogoPlaceholder'
import experienceLevelIcon from 'containers/JobBoardPage/assets/experienceLevelIcon'
import 'draft-js/dist/Draft.css'

const JobListing = ({
  jobListing: {
    employer: {
      name: employer_name,
      company_description,
      company_logo_thumbnail,
      company_size,
      industries
    },
    name,
    geo_locations,
    work_type,
    experience_level,
    remote,
    created_at,
    salary_range_min,
    salary_range_max,
    role_description,
    visa_sponsorship
  }
}) => {
  const handleCompanyLogo = () => {
    return company_logo_thumbnail ? (
      <JobImage backgroundImage={company_logo_thumbnail} />
    ) : (
      <JobImage>{companyLogoPlaceholder}</JobImage>
    )
  }

  const handleIndustries = () => (
    <JobDetail>
      {suitcaseIcon}
      {industries.length === 1 && industries[0]}
      {industries.length > 1 && (
        <IndustryLocationToolTip>
          {`${industries[0]} +${industries.length - 1}`}
          <div>{industries.join('⋅')}</div>
        </IndustryLocationToolTip>
      )}
    </JobDetail>
  )

  const handleDate = createdAt => {
    const dateCreated = moment(createdAt)
    const diffFromToday = moment().diff(dateCreated, 'days')

    if (!diffFromToday) {
      return 'new'
    } else if (diffFromToday <= 7) {
      const dayOrDays = diffFromToday !== 1 ? 'days' : 'day'

      return `${diffFromToday} ${dayOrDays} ago`
    } else {
      return dateCreated.format('MMM D')
    }
  }

  const handleSize = () => {
    let convertedSize

    switch (company_size) {
      case 'Small':
        convertedSize = '1-100'
        break
      case 'Medium':
        convertedSize = '100-1,000'
        break
      case 'Large':
        convertedSize = '1,000+'
        break
      default:
        break
    }

    return (
      <JobDetail>
        {groupIcon}
        {convertedSize} Employees
      </JobDetail>
    )
  }

  const handleSalary = () => {
    const minSalary = salary_range_min?.toString().slice(0, -3)
    const maxSalary = salary_range_max?.toString().slice(0, -3)

    return (
      <JobDetail>
        {incomeIcon}${minSalary}
        {maxSalary && `-${maxSalary}`}K Salary
      </JobDetail>
    )
  }

  const handleDescription = section => {
    const sectionDetails = {
      company: { header: 'About Company', body: company_description },
      role: { header: 'Role Description', body: role_description }
    }
    const { header, body } = sectionDetails[section]

    const editorState = EditorState.createWithContent(
      convertFromRaw(
        markdownToDraft(body, {
          blockStyles: {
            ins_open: 'UNDERLINE'
          },
          remarkableOptions: {
            enable: {
              inline: 'ins'
            }
          }
        })
      )
    )

    return (
      <>
        <SectionHeader>{header}</SectionHeader>
        <SectionContent>
          <Editor editorState={editorState} readOnly={true} />
        </SectionContent>
      </>
    )
  }

  return (
    <JobInfoWrapper>
      <CompanyLogo companyLogo={company_logo_thumbnail}>
        {handleCompanyLogo()}
      </CompanyLogo>
      <JobInfo>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{employer_name}</p>
          <DatePosted>{handleDate(created_at)}</DatePosted>
        </div>
        <p>
          <b>{name}</b>
        </p>
        <JobInfoDetail>
          <JobLocationInfo geoLocations={geo_locations} remote={remote} />
          {experience_level && (
            <ExperienceLevel>
              {experienceLevelIcon} {experience_level}
            </ExperienceLevel>
          )}
        </JobInfoDetail>
        <div style={{ display: 'flex', marginTop: '24px' }}>
          {handleIndustries()}
          <EmploymentType>
            {clockIcon} {work_type}
          </EmploymentType>
          {company_size && handleSize()}
          {salary_range_min && handleSalary()}
        </div>
        <VisaNotice available={visa_sponsorship}>
          Visa Sponsorship Available
        </VisaNotice>
        {company_description && handleDescription('company')}
        {role_description && handleDescription('role')}
      </JobInfo>
    </JobInfoWrapper>
  )
}

export default JobListing

const JobInfoDetail = styled.div`
  display: flex;
`

const JobInfoWrapper = styled(JobInfoDetail)``

const CompanyLogo = styled.div`
  border: ${({ companyLogo, theme }) =>
    !companyLogo && `1px solid ${theme.color.border.gray}`};
  border-radius: 4px;
  height: fit-content;
`

const JobInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin: 0 0 0 16px;
  width: 542px;
`

const EmploymentType = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.text.subText};
  display: flex;
  font-size: 14px;
  margin-right: 16px;
  svg {
    margin-right: 5px;
  }
`

const ExperienceLevel = styled(EmploymentType)``

const JobDetail = styled(EmploymentType)`
  margin-right: 16px;
`

const SectionHeader = styled.div`
  color: #2c2c2c;
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 15px 0;
`

const SectionContent = styled.div`
  color: #2c2c2c;
  margin: 0 0 44px;
  h1,
  h3,
  h4,
  h5,
  h6 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    margin-top: 5px;
  }
  .DraftEditor-root {
    span {
      font-size: 14px;
      font-weight: inherit;
      letter-spacing: 0.5px;
      line-height: 24px;
    }
    h2 {
      margin: 16px 0;
      span {
        font-size: 18px;
        font-weight: bold;
      }
    }
    div {
      margin-bottom: 5px;
    }
  }
`

const DatePosted = styled.div`
  color: ${({ theme }) => theme.color.text.tag};
  border-radius: 14px;
  font-size: 12px;
  font-weight: bold;
`

const VisaNotice = styled.div`
  color: #845e12;
  background: #f3ebd5;
  border: 1px solid #ca9e47;
  border-radius: 14px;
  font-size: 12px;
  font-weight: bold;
  margin: 13px 0 36px;
  padding: 4px 16px;
  width: fit-content;
  ${({ available }) => !available && 'margin: 0; visibility: hidden;'}
`

const JobImage = styled.div`
  background: #fff
    ${({ backgroundImage }) =>
      backgroundImage && `url("${backgroundImage}") center center no-repeat`};
  background-size: contain;
  border-radius: 4px;
  height: 96px;
  width: 96px;
  svg {
    height: 100%;
    width: 100%;
  }
`
const IndustryLocationToolTip = styled.div`
  color: ${({ theme }) => theme.color.text.subText};
  cursor: pointer;
  font-size: inherit;
  position: relative;
  border-bottom: 0.5px dotted #5f5d66;
  div {
    background-color: ${({ theme }) => theme.color.bg.dark};
    border-radius: 4px;
    color: ${({ theme }) => theme.color.text.lite};
    display: none;
    font-size: inherit;
    font-style: inherit;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    position: absolute;
    text-decoration: none;
    width: max-content;
  }
  :hover div {
    display: block;
  }
`
